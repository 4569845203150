import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import posthog from "posthog-js";
import { debounce } from "lodash";

// Initialize PostHog
posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
  api_host: process.env.REACT_APP_POSTHOG_HOST || "https://app.posthog.com",
  capture_pageview: false, // We'll handle this manually in App.js
});

// Function to suppress specific errors
const suppressErrors = () => {
  // Suppress console errors
  const originalConsoleError = console.error;
  console.error = (...args) => {
    // Ignore all console errors in production
    if (process.env.NODE_ENV === "production") return;

    // In development, only log non-React warnings
    if (process.env.NODE_ENV === "development" && !/^Warning:/.test(args[0])) {
      originalConsoleError(...args);
    }
  };

  // Suppress window errors
  window.addEventListener(
    "error",
    (event) => {
      event.stopImmediatePropagation();
      event.preventDefault();
      return true;
    },
    true
  );

  // Suppress unhandled promise rejections
  window.addEventListener("unhandledrejection", (event) => {
    event.preventDefault();
  });

  // Patch React's error overlay
  if (process.env.NODE_ENV === "development") {
    const originalError = console.error;
    console.error = (...args) => {
      if (!/React will try to recreate this component tree/.test(args[0])) {
        originalError(...args);
      }
    };
  }

  // Patch ResizeObserver
  const originalResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends originalResizeObserver {
    constructor(callback) {
      super((entries, observer) => {
        const suppressedCallback = debounce((entries, observer) => {
          try {
            callback(entries, observer);
          } catch (e) {
            if (
              e.message !==
              "ResizeObserver loop completed with undelivered notifications."
            ) {
              throw e;
            }
            // Else, swallow the specific ResizeObserver error
          }
        }, 200);
        suppressedCallback(entries, observer);
      });
    }
  };
};

// Apply error suppression
suppressErrors();

// Wrap the entire app in an error boundary that silently catches errors
const SilentErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={() => null}>{children}</ErrorBoundary>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <SilentErrorBoundary>
      <App />
    </SilentErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
