import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Background,
  Controls,
  Handle,
  addEdge,
  ReactFlowProvider,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { ErrorBoundary } from "react-error-boundary";
import posthog from "posthog-js";
import LoadingSpinner from "./components/LoadingSpinner";

const mdParser = new MarkdownIt();

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

console.log("BACKEND_URL in Graph.js:", BACKEND_URL);

const CustomNode = ({ id, data }) => {
  const handleLinkClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (data?.url) {
        console.log("Sending openUrlInExtension message:", data.url, id);
        window.postMessage(
          {
            type: "FROM_PAGE",
            action: "openUrlInExtension",
            url: data.url,
            submissionId: id,
          },
          "*"
        );
      } else {
        console.error("Missing url:", data);
        setTimeout(() => {
          alert(
            "Please ensure you have installed Heretic.School's Chrome Extension, or contact us andrew@heretic.school"
          );
        }, 1000);
      }
    },
    [data, id]
  );

  if (!data || typeof data !== "object") {
    console.error("Invalid data for CustomNode:", data);
    return null;
  }

  return (
    <div
      className="p-4 rounded-lg bg-gradient-to-r from-gray-800 to-gray-700 border-2 border-blue-500 text-white shadow-lg backdrop-filter backdrop-blur-sm max-w-4xl flex flex-col items-center justify-center"
      onClick={() => data.onExpand && data.onExpand(id)}
    >
      {data.category && (
        <span className="text-xs font-semibold bg-blue-500 text-white px-2 py-1 rounded-full mb-2">
          {data.category}
        </span>
      )}
      <strong className="text-lg text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 text-center">
        {data.label || data.title || "Untitled"}
      </strong>
      {data.expanded && (
        <>
          <p className="mt-2 text-center">
            <a
              href={data.url || "#"}
              onClick={handleLinkClick}
              className="text-blue-400 hover:text-blue-300 underline"
            >
              {data.url || "No URL provided"}
            </a>
          </p>
          <p className="mt-2 text-gray-300 text-center">
            {data.description || "No description available"}
          </p>
        </>
      )}
      <Handle type="source" position="bottom" id={`${id}-source`} />
      <Handle type="target" position="top" id={`${id}-target`} />
    </div>
  );
};

const ProjectNode = ({ id, data }) => {
  const [submission, setSubmission] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEditorChange = ({ text }) => {
    setSubmission(text);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    data.onSubmit(submission, id).finally(() => setIsLoading(false));
    setSubmission("");
  };

  // Ensure data.project exists before accessing its properties
  const project = data.project || {};
  const { title, description, instructions, category } = project;

  return (
    <div className="bg-gradient-to-r from-purple-800 to-blue-950 rounded-lg p-6 shadow-xl border border-pink-500 text-white backdrop-filter backdrop-blur-sm max-w-2xl w-96">
      {category && (
        <div className="flex justify-center mb-2">
          <span className="text-xs font-semibold bg-blue-500 text-white px-2 py-1 rounded-full">
            {category}
          </span>
        </div>
      )}
      <h3
        className="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-300 cursor-pointer"
        onClick={() => data.onExpand && data.onExpand(id)}
      >
        {title || "Untitled Project"}
      </h3>
      {data.expanded && (
        <>
          <p className="mb-4 text-gray-300 text-sm">
            {description || "No description available"}
          </p>
          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2 text-pink-300">
              Instructions:
            </h4>
            <pre className="text-gray-300 text-sm whitespace-pre-wrap font-sans">
              {instructions || "No instructions available"}
            </pre>
          </div>
          {!data.feedback || !data.feedback.feedback ? (
            <form
              onSubmit={handleSubmit}
              className="mt-6"
              onClick={(e) => e.stopPropagation()}
            >
              <MdEditor
                value={submission}
                style={{ height: "200px" }}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleEditorChange}
                className="mb-4 bg-purple-900 bg-opacity-50 rounded-lg overflow-hidden"
                view={{ menu: true, md: true, html: false }}
                canView={{
                  menu: true,
                  md: true,
                  html: false,
                  fullScreen: false,
                  hideMenu: false,
                }}
                config={{
                  view: {
                    menu: true,
                    md: true,
                    html: false,
                  },
                  table: {
                    maxRow: 5,
                    maxCol: 6,
                  },
                  syncScrollMode: ["leftFollowRight", "rightFollowLeft"],
                }}
                plugins={[
                  "header",
                  "font-bold",
                  "font-italic",
                  "font-underline",
                  "font-strikethrough",
                  "list-unordered",
                  "list-ordered",
                  "block-quote",
                  "block-wrap",
                  "block-code-inline",
                  "block-code-block",
                  "table",
                  "image",
                  "link",
                  "clear",
                  "logger",
                  "mode-toggle",
                  "full-screen",
                ]}
              />
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-gradient-to-r from-pink-500 to-purple-500 text-white font-bold rounded-lg hover:from-pink-600 hover:to-purple-600 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50"
                  disabled={isLoading}
                >
                  Submit Project
                </button>
              )}
            </form>
          ) : (
            <div className="mt-4 bg-purple-900 bg-opacity-50 rounded-lg p-4">
              <h4 className="text-lg font-semibold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-300">
                Feedback:
              </h4>
              <p className="mb-3 text-gray-300 text-sm">
                {data.feedback.feedback}
              </p>
              <p className="mb-2 text-green-400 text-sm">
                <strong>Strength:</strong> {data.feedback.strength}
              </p>
              <p className="mb-2 text-yellow-400 text-sm">
                <strong>Area for Improvement:</strong> {data.feedback.weakness}
              </p>
            </div>
          )}
        </>
      )}
      <Handle
        type="source"
        position="bottom"
        id={`${id}-source`}
        className="w-3 h-3 bg-pink-500"
      />
      <Handle
        type="target"
        position="top"
        id={`${id}-target`}
        className="w-3 h-3 bg-pink-500"
      />
    </div>
  );
};

const UpdateInfoPopup = ({
  onClose,
  onSubmit,
  currentDreamJob,
  currentPersonalInterest,
}) => {
  const [dreamJob, setDreamJob] = useState(currentDreamJob);
  const [personalInterest, setPersonalInterest] = useState(
    currentPersonalInterest
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(dreamJob, personalInterest);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Update Your Information</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="dreamJob"
            >
              Dream Job
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="dreamJob"
              type="text"
              value={dreamJob}
              onChange={(e) => setDreamJob(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="personalInterest"
            >
              Personal Interest
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="personalInterest"
              type="text"
              value={personalInterest}
              onChange={(e) => setPersonalInterest(e.target.value)}
            />
          </div>
          <div className="flex justify-end">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              type="submit"
            >
              Update
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const CentralNode = ({ data, onUpdateClick }) => {
  return (
    <div
      className="p-4 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 border-2 border-white text-white shadow-lg backdrop-filter backdrop-blur-sm max-w-sm flex flex-col items-center justify-center cursor-pointer"
      onClick={onUpdateClick}
    >
      <div className="mb-2 text-center">
        <strong className="text-sm text-pink-300">Dream Job:</strong>
        <p className="text-lg font-bold">{data.dreamjob}</p>
      </div>
      <div className="text-center">
        <strong className="text-sm text-green-300">Biggest Interest:</strong>
        <p className="text-lg font-bold">{data.biggest_personal_interest}</p>
      </div>
      <div className="mt-2 text-xs text-gray-300">Click to update</div>
      <Handle type="source" position="bottom" id="central-source" />
    </div>
  );
};

const NewBranchNode = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleConfirm = () => {
    if (!selectedCategory) return;
    setIsLoading(true);
    data
      .onConfirm(selectedCategory)
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error confirming new branch:", error);
        setIsLoading(false);
      });
  };

  return (
    <div className="p-4 rounded-lg bg-gradient-to-r from-yellow-400 to-orange-500 border-2 border-white text-white shadow-lg backdrop-filter backdrop-blur-sm max-w-sm flex flex-col items-center justify-center">
      <h3 className="text-lg font-bold mb-2">Add a New Branch?</h3>
      <p className="mb-4">Choose a category to explore:</p>
      <select
        className="w-full p-2 mb-4 text-gray-700 bg-white rounded-md"
        onChange={(e) => setSelectedCategory(e.target.value)}
        value={selectedCategory}
        disabled={isLoading}
      >
        <option value="">Select a category</option>
        {data.availableCategories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
          onClick={handleConfirm}
          disabled={!selectedCategory}
        >
          Confirm
        </button>
      )}
      <Handle type="target" position="top" id="new-branch-target" />
    </div>
  );
};

const ErrorFallback = ({ error }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 400;
  const nodeHeight = 150;

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({
    rankdir: direction,
    ranksep: 125,
    nodesep: 150,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const centralNode = nodes.find((node) => node.id === "central-node");
  const otherNodes = nodes.filter((node) => node.id !== "central-node");

  otherNodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // Assign positions based on the angle from the central node
    const dx = nodeWithPosition.x - (centralNode?.position?.x || 0);
    const dy = nodeWithPosition.y - (centralNode?.position?.y || 0);
    const angle = Math.atan2(dy, dx);

    // Determine the best source and target positions based on the angle
    if (angle > -Math.PI / 4 && angle <= Math.PI / 4) {
      node.targetPosition = "left";
      node.sourcePosition = "right";
    } else if (angle > Math.PI / 4 && angle <= (3 * Math.PI) / 4) {
      node.targetPosition = "top";
      node.sourcePosition = "bottom";
    } else if (angle > (3 * Math.PI) / 4 || angle <= (-3 * Math.PI) / 4) {
      node.targetPosition = "right";
      node.sourcePosition = "left";
    } else {
      node.targetPosition = "bottom";
      node.sourcePosition = "top";
    }

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
  });

  if (centralNode) {
    centralNode.position = { x: 0, y: 0 };
  }

  return { nodes, edges };
};

const Graph = ({ user, setError }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const location = useLocation();
  const data = location.state?.recommendation;
  const [submissionId, setSubmissionId] = useState(null);

  // Create refs for the functions
  const fetchAndUpdateProjectRef = useRef(null);
  const handleSubmitProjectRef = useRef(null);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
      posthog.capture("edge_connected", { params });
    },
    [setEdges]
  );

  const onNodeClick = useCallback(
    (event, node) => {
      if (!node || !node.id) {
        console.error("Invalid node clicked:", node);
        return;
      }

      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: {
                ...n.data,
                expanded: !n.data.expanded,
              },
            };
          }
          return n;
        })
      );

      posthog.capture("node_expanded", { nodeId: node.id });
    },
    [setNodes]
  );

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [centralNodeData, setCentralNodeData] = useState(null);
  const [projectsUntilNextBranch, setProjectsUntilNextBranch] = useState(null);

  const handleUpdateClick = useCallback(() => {
    setShowUpdatePopup(true);
  }, []);

  const handleUpdateSubmit = useCallback(
    (newDreamJob, newPersonalInterest) => {
      fetch(`${BACKEND_URL}/api/update_user_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          dreamJob: newDreamJob,
          personalInterest: newPersonalInterest,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            console.log(data.message);
            // Update all nodes with the new information
            setNodes((prevNodes) =>
              prevNodes.map((node) => {
                if (
                  node.data &&
                  (node.data.dreamjob || node.data.biggest_personal_interest)
                ) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      dreamjob: newDreamJob,
                      biggest_personal_interest: newPersonalInterest,
                    },
                  };
                }
                return node;
              })
            );
            setCentralNodeData((prevData) => ({
              ...prevData,
              dreamjob: newDreamJob,
              biggest_personal_interest: newPersonalInterest,
            }));
            setShowUpdatePopup(false);
            fetchAndUpdateProjectRef.current(); // Refresh the graph
          } else {
            throw new Error(data.error || "Failed to update user info");
          }
        })
        .catch((error) => {
          console.error("Error updating user info:", error);
          setError(error);
        });
    },
    [user.id, setError, setNodes]
  );

  const handleConfirmNewBranch = useCallback(
    (category) => {
      return fetch(`${BACKEND_URL}/api/generate_new_branch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          category: category,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setNodes((nds) => [...nds, data.newNode]);
          setEdges((eds) => [...eds, data.newEdge]);
        })
        .catch((error) => {
          console.error("Error generating new branch:", error);
          setError(error);
        });
    },
    [user.id, setNodes, setEdges, setError]
  );

  fetchAndUpdateProjectRef.current = useCallback(() => {
    const url = submissionId
      ? `${BACKEND_URL}/api/get_project?submissionId=${submissionId}&userId=${user.id}`
      : `${BACKEND_URL}/api/get_project?userId=${user.id}`;

    console.log("Fetching project data from:", url);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Received project data:", data);
        const validNodes = data.nodes.filter((node) => node.data);
        const validEdges = data.edges.filter(
          (edge) => edge.source && edge.target
        );
        console.log("Valid nodes:", validNodes);
        console.log("Valid edges:", validEdges);

        const updatedNodes = validNodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            onExpand: onNodeClick,
            onSubmit:
              node.type === "projectNode"
                ? handleSubmitProjectRef.current
                : undefined,
            onUpdateClick:
              node.type === "centralNode" ? handleUpdateClick : undefined,
          },
        }));

        const centralNode = validNodes.find(
          (node) => node.id === "central-node"
        );
        if (centralNode) {
          setCentralNodeData(centralNode.data);
        }

        setProjectsUntilNextBranch(data.projectsUntilNextBranch);

        if (data.offerNewBranch) {
          const newBranchNode = {
            id: "new-branch-node",
            type: "newBranchNode",
            data: {
              availableCategories: data.availableCategories,
              onConfirm: handleConfirmNewBranch,
            },
            position: { x: 0, y: -200 }, // Position above central node
          };
          updatedNodes.push(newBranchNode);

          const newBranchEdge = {
            id: "e-central-new-branch",
            source: "central-node",
            target: "new-branch-node",
            type: "smoothstep",
          };
          validEdges.push(newBranchEdge);
        }

        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(updatedNodes, validEdges);

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
        setError(error);
      });
  }, [
    submissionId,
    user.id,
    onNodeClick,
    setError,
    setNodes,
    setEdges,
    handleUpdateClick,
    handleConfirmNewBranch,
  ]);

  handleSubmitProjectRef.current = useCallback(
    (submission, projectSubmissionId) => {
      console.log("Submitting project:", submission, projectSubmissionId);
      const url = `${BACKEND_URL}/api/submit_project`;
      const submitData = {
        projectSubmission: submission,
        submissionId: projectSubmissionId,
        userId: user.id,
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submitData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((responseData) => {
          console.log("Response status:", responseData.status);
          console.log("Response data:", responseData);
          if (responseData.status === "success") {
            fetchAndUpdateProjectRef.current();
          }
        })
        .catch((error) => {
          console.error("Error submitting project:", error);
          setError(error);
        });
    },
    [user.id, setError]
  );

  useEffect(() => {
    if (data && data.submissionId) {
      setSubmissionId(data.submissionId);
      console.log("Set submissionId:", data.submissionId);
    }
  }, [data]);

  useEffect(() => {
    if (submissionId) {
      console.log("Fetching project data for submissionId:", submissionId);
      fetchAndUpdateProjectRef.current();
    } else {
      console.log("No submissionId available, fetching all projects");
      fetchAndUpdateProjectRef.current();
    }
  }, [submissionId]);

  const nodeTypes = useMemo(
    () => ({
      custom: CustomNode,
      projectNode: ProjectNode,
      centralNode: (props) => (
        <CentralNode {...props} onUpdateClick={handleUpdateClick} />
      ),
      newBranchNode: NewBranchNode,
    }),
    [handleUpdateClick]
  );

  return (
    <>
      <Header />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="w-screen h-screen relative">
          {projectsUntilNextBranch !== null && projectsUntilNextBranch > 0 && (
            <div className="absolute bottom-4 right-4 bg-gray-800 text-white p-2 rounded-md z-10">
              Projects until next branch: {projectsUntilNextBranch}
            </div>
          )}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
            fitView
            fitViewOptions={{ padding: 0.2, includeHiddenNodes: false }}
            defaultEdgeOptions={{ type: "smoothstep" }}
            className="bg-gray-900"
          >
            <Background variant="dots" gap={12} size={1} color="#2a2a2a" />
            <Controls className="bg-gray-700 text-white" />
          </ReactFlow>
          {showUpdatePopup && centralNodeData && (
            <UpdateInfoPopup
              onClose={() => setShowUpdatePopup(false)}
              onSubmit={handleUpdateSubmit}
              currentDreamJob={centralNodeData.dreamjob}
              currentPersonalInterest={
                centralNodeData.biggest_personal_interest
              }
            />
          )}
        </div>
      </ErrorBoundary>
    </>
  );
};

// Wrap the Graph component with ReactFlowProvider
const SafeGraph = (props) => {
  const [error, setError] = useState(null);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <ReactFlowProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Graph {...props} setError={setError} />
      </ErrorBoundary>
    </ReactFlowProvider>
  );
};

export default SafeGraph;
