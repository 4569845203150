import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header"; // Import the Header component
import posthog from "posthog-js";
import { useEffect } from "react";

const Gradient = ({ children }) => (
  <span className="text-transparent bg-gradient-to-br from-purple-500 to-indigo-500 bg-clip-text">
    {children}
  </span>
);

const LandingPage = () => {
  useEffect(() => {
    posthog.capture("landing_page_viewed");
  }, []);

  return (
    <div className="bg-gray-950 text-white">
      <Header /> {/* Use the Header component here */}
      <TempBanner />
      <main>
        <Hero />
        <HowItWorks />
        <DemoVideo /> {/* Add this new section */}
        <WhatHereticMeans />
        <QuoteBlock />
        <Highlights />
        <FAQ />
        <AboutUs />
      </main>
      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Heretic.School. All rights reserved.</p>
          <p className="mt-2">
            <Link
              to="/privacy-policy.html"
              className="text-indigo-400 hover:text-indigo-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </p>
        </div>
      </footer>
    </div>
  );
};

const TempBanner = () => (
  <div className="bg-yellow-500 text-black py-2 px-4 text-center">
    <p className="text-sm md:text-base font-semibold">
      Heretic.School is not currently designed for use on mobile phones - please
      use a computer to use the platform to its fullest extent.
    </p>
  </div>
);

const Hero = () => (
  <section className="relative py-20 text-center">
    <div
      className="absolute inset-0 bg-cover bg-center"
      style={{ backgroundImage: "url('/background.png')" }}
    />
    <div className="absolute inset-0 bg-black opacity-50" />
    <div className="relative z-10 container mx-auto px-4">
      <h1 className="text-5xl font-bold mb-6">
        Learning for the <Gradient>Most Exceptional Teens</Gradient>
      </h1>
      <p className="text-xl text-white-300 mb-8 max-w-3xl mx-auto font-bold">
        Stop stifling your teen's potential with the traditional{" "}
        <Gradient>One-Size-Fits-All</Gradient> approach.{" "}
        <Gradient>Start free today</Gradient> and watch your teen thrive with a
        learning experience tailored to their unique interests and career goals.
      </p>
      <Link
        to="/graph"
        onClick={() => posthog.capture("cta_clicked", { location: "hero" })}
        className="bg-violet-500 text-white px-8 py-4 rounded-lg text-lg font-bold hover:bg-violet-700 transition duration-300 inline-block"
      >
        Start Learning for Free
      </Link>
    </div>
  </section>
);

const HowItWorks = () => (
  <section className="py-20 bg-gray-900">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">
        <Gradient>How Heretic.School Works</Gradient>
      </h2>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <StepCard
          number="1"
          title="A Learning Lifestyle, Not a Box to Check"
          description="The orthodoxy of public schooling thinks of education as a box to check off, a credential to be considered as 'normal'. This is wrong. Learning isn't something that is ever completed. It is a way of being in the world, one that starts at birth and never ends. Our platform is designed to foster an innate love of learning based on what they already love and care about."
        />
        <StepCard
          number="2"
          title="Hyper-Personalized Content and Projects"
          description="Based on your teen's interests, goals, and current knowledge, our platform adapts second-by-second to provide the most engaging and relevant content and projects to keep them learning and growing. If they are struggling with something in particular, we give them a hand so they can get back in the flow. If they are breezing through something, we push the challenge to the next level to give them something to bite their teeth into."
        />
        <StepCard
          number="3"
          title="Homeschooling | Not School-at-Home"
          description="Heretic.School is not another public-school curriculum knock-off to recreate the mediocrity of the public-school system in your home. It is a platform that allows your teen to learn at their own pace and in their own way, harnessing their unique talents, passions, and interests to supercharge their learning so that they can become experts in what they actually want to do."
        />
        <StepCard
          number="4"
          title="Our Stance on Education"
          description="At Heretic.School, we're neither pro nor anti-secular education. Our mission is to provide powerful tools that empower families to let students learn what truly matters to them. We believe in the freedom of choice in education. If religion is important to a student's journey, we support that. If it's not, that's equally valid. Our platform is flexible and adaptable to each student's unique interests, beliefs, and goals."
        />
      </div>
      <div className="mt-12 text-center">
        <p className="text-lg text-gray-300 mb-6">
          Experience a learning platform that evolves with your teen, fostering
          curiosity and mastery.
        </p>
        <Link
          to="/graph"
          onClick={() =>
            posthog.capture("cta_clicked", { location: "how_it_works" })
          }
          className="bg-indigo-500 text-white px-6 py-3 rounded-lg text-lg font-bold hover:bg-indigo-600 transition duration-300 inline-block"
        >
          See How It Works
        </Link>
      </div>
    </div>
  </section>
);

const StepCard = ({ number, title, description }) => (
  <div className="bg-gray-800 p-6 rounded-lg text-center">
    <div className="w-12 h-12 bg-indigo-500 rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">
      {number}
    </div>
    <h3 className="text-xl font-bold mb-4">
      <Gradient>{title}</Gradient>
    </h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const DemoVideo = () => {
  useEffect(() => {
    posthog.capture("demo_video_viewed");
  }, []);

  return (
    <section className="py-5 bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          <Gradient>Get Started with Heretic.School</Gradient>
        </h2>
        <div className="max-w-4xl mx-auto">
          <div
            style={{
              position: "relative",
              paddingBottom: "80.35714285714286%",
              height: 0,
            }}
          >
            <iframe
              src="https://www.loom.com/embed/c308e59e6aa541abaaf4215a67141eb8?sid=9ce98e83-edcf-41aa-ad90-6f92bb544210"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Heretic.School Demo Video"
            ></iframe>
          </div>
          <p className="text-center mt-8 text-lg text-gray-300">
            Watch this quick demo to see how Heretic.School can revolutionize
            your teen's learning experience.
          </p>
        </div>
      </div>
    </section>
  );
};

const WhatHereticMeans = () => (
  <section className="py-20 relative">
    <div
      className="absolute inset-0 bg-cover bg-top opacity-20"
      style={{ backgroundImage: "url('/vision.png')" }}
    />
    <div className="relative z-10 container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">
        What Being a{" "}
        <span className="italic">
          <Gradient>Heretic</Gradient>
        </span>{" "}
        Means to Us
      </h2>
      <div className="text-lg text-gray-300 max-w-3xl mx-auto">
        <p className="mb-6 font-bold">
          In a world where public schools and their private counterparts
          represent the educational orthodoxy,
          <Gradient> Heretic.School </Gradient> stands for those who dare to
          challenge the status quo.
        </p>
        <p className="mb-6 font-bold">
          We believe that the status quo breeds mediocrity and conformity,
          stifling the potential of our most exceptional minds. The true
          heretics are those who refuse to accept this limitation, who seek to
          go far beyond what traditional education offers.
        </p>
        <p className="mb-6 font-bold">
          At Heretic.School, we empower these educational rebels - the gifted,
          the ambitious, the relentlessly curious. We provide a platform for
          them to break free from the constraints of conventional learning, to
          pursue knowledge with intensity and purpose, and to forge their own
          paths to excellence.
        </p>
        <p className="mb-6 font-bold">
          Join us in rejecting the mundane and embracing the extraordinary.
          Because in the realm of learning, being a heretic means refusing to
          settle for anything less than your full, exceptional potential.
        </p>
      </div>
    </div>
  </section>
);

const QuoteBlock = () => (
  <section className="py-20 relative">
    <div
      className="absolute inset-0 bg-cover bg-center opacity-20"
      style={{ backgroundImage: "url('/augmented.png')" }}
    />
    <div className="relative z-10 container mx-auto px-4">
      <div className="max-w-4xl mx-auto bg-gray-800 rounded-lg p-8 shadow-lg">
        <div className="text-6xl text-indigo-500 absolute -top-4 left-4">"</div>
        <blockquote className="text-xl md:text-2xl italic text-center relative z-10">
          <p className="mb-6">
            Learning can only happen when a child is interested. If he's not
            interested, it's like throwing marshmallows at his head and calling
            it eating.
          </p>
          <footer className="text-lg md:text-xl font-bold mt-4">
            <Gradient>- Katrina Gutleben</Gradient>
          </footer>
        </blockquote>
        <div className="text-6xl text-indigo-500 absolute -bottom-8 right-4">
          "
        </div>
      </div>
    </div>
  </section>
);

const Highlights = () => (
  <section className="py-20 bg-gray-900">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">
        <Gradient>Why Heretic.School?</Gradient>
      </h2>
      <div className="grid md:grid-cols-2 gap-8">
        <HighlightCard
          title="Personalized Learning, Accelerated Mastery"
          content="At Heretic.School, your teen's learning journey is tailored to their unique interests, pace, and abilities. Our adaptive platform ensures that your high-achiever is consistently challenged and stimulated. The failure of the orthox model is the one-size-fits-all approach to education that believes that as long as they check off the boxes, they have actually learned something valuable. This is simply not true: how much does the average adult remember of their middle school or high school education? What percentage of it have they actually applied? Heretic.School is designed to change that."
        />
        <HighlightCard
          title="Authentic Assessments, Real-World Ready"
          content="We've revolutionized assessments by abandoning traditional multiple-choice exams in favor of authentic, creative projects that mirror real-life experiences. Heretic.School is designed to help students become deeply ingrained in the real world career they aspire to enter. If your teen wants to work in tech and loves history, projects like creating a history-based video game or a website about a historical figure allows them to combine their passions and interests in a way that is both fun and deeply engaging. This is the kind of learning that sticks."
        />
      </div>
    </div>
  </section>
);

const HighlightCard = ({ title, content }) => (
  <div className="bg-gray-800 p-6 rounded-lg">
    <h3 className="text-xl font-bold mb-4">
      <Gradient>{title}</Gradient>
    </h3>
    <p className="text-white-200">{content}</p>
  </div>
);

const FAQ = () => (
  <section className="py-20 relative">
    <div
      className="absolute inset-0 bg-cover bg-top opacity-10"
      style={{ backgroundImage: "url('/faq.png')" }}
    />
    <div className="relative z-10 container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">
        <Gradient>Frequently Asked Questions</Gradient>
      </h2>
      <div className="max-w-3xl mx-auto">
        <FAQItem
          question="What age range is best suited for Heretic.School?"
          answer="Heretic.School is designed for highly motivated teens who are looking for a more challenging and engaging learning experience. While younger students can find value in the platform, we have designed it--in its current form--for teens 13-18 years old as the ideal age range for this freedom and independence of learning."
        />
        <FAQItem
          question="How do I get started with Heretic.School?"
          answer="Getting started with Heretic.School is simple. Click the 'Start Learning for Free' button, create your account, and input your child's dream job and personal interests. Our adaptive platform will guide your child through a personalized learning journey."
        />
        {/* Add more FAQ items as needed */}
      </div>
    </div>
  </section>
);

const FAQItem = ({ question, answer }) => (
  <div className="mb-8">
    <h3 className="text-xl font-bold mb-2">
      <Gradient>{question}</Gradient>
    </h3>
    <p className="text-gray-300">{answer}</p>
  </div>
);

const AboutUs = () => (
  <section className="py-20 bg-gray-900">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">
        <Gradient>About Us</Gradient>
      </h2>
      <div className="flex flex-col items-center">
        <img
          src="/spaceshuttleandrew.png"
          alt="Andrew Garber"
          className="w-32 h-32 rounded-full mb-8"
        />
        <h3 className="text-2xl font-bold mb-4">
          Andrew Garber, Founder @ Heretic.School
        </h3>
        <div className="max-w-3xl text-center">
          <p className="mb-4">
            Hi! I'm Andrew, a former homeschooler and the founder of
            Heretic.School. I was a student in public school until my Sophomore
            year of high school, when I decided to take my education into my own
            hands. I know first-hand how much of a difference personalized
            learning can make, so I decided to create a platform that makes it
            easy to start homeschooling and make the most of their educational
            journey.
          </p>
          <p className="mb-4">
            My path to becoming a homeschooler was not straightforward. The idea
            of leaving the relative "safety" of public school, the conventional
            path, was a hard sell to my parents. I had to convince them that
            homeschooling was a viable option, that I would be able to get into
            a good college, and have the basis for a successful career. I spent
            months trying to convince them that homeschooling was something we
            could make work to give me the kind of education I wanted.
          </p>
          <p className="mb-4">
            That's why I created Heretic.School - melding the best parts of
            various curricula and learning styles into a cohesive curriculum was
            difficult, and simply not available in one place. I wanted to remove
            all of the friction from the process of homeschooling, and learning
            in general, so that it would be push-button easy to get started.
            Homeschooling has given me the freedom to create Heretic.School, and
            I want to give that same freedom to other students for whatever
            their interest is. Even once I started homeschooling it wasn't as
            personalized as I had dreamed it would be. I had to spend hours
            searching for resources, and I still had to piece together a
            curriculum that would work for me. Heretic.School is designed to
            make that process easy and engaging for parents and students alike.
          </p>
          <p className="mb-4">
            I love talking to anyone who is homeschooling or thinking about it,
            and am always open to feedback and suggestions. Feel free to reach
            out to me at{" "}
            <Gradient>
              <a href="mailto:andrew@heretic.school">andrew@heretic.school</a>
            </Gradient>{" "}
            with any questions or comments.
          </p>
          <p className="mb-4">
            <Gradient>
              <a
                href="https://open.spotify.com/episode/7koZlbasYW74ZPYyQT33xu?si=a8b89c80cce64c7f"
                target="_blank"
                rel="noopener noreferrer"
              >
                ➡️ If you want to hear more about my story and the genesis of Heretic.School, check out this podcast episode. ⬅️
              </a>
            </Gradient>{" "}
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default LandingPage;
