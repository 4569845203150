import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Only log errors that are not related to ResizeObserver
    if (
      error.message !==
      "ResizeObserver loop completed with undelivered notifications."
    ) {
      console.log("Error caught by ErrorBoundary:", error, errorInfo);
    }
    // Else, silently suppress the ResizeObserver error
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback || null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
