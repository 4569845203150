import React, { useEffect } from "react";
import Header from "./Header";
import posthog from "posthog-js";

const ExtensionDownload = () => {
  useEffect(() => {
    posthog.capture("extension_download_page_viewed");
  }, []);

  const handleDownloadClick = () => {
    posthog.capture("extension_download_clicked");
  };

  return (
    <div className="bg-gray-950 text-white min-h-screen">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto bg-gray-800 bg-opacity-80 backdrop-filter backdrop-blur-xl rounded-2xl shadow-2xl p-8">
          <h1 className="text-4xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-400">
            Install Heretic.School Chrome Extension
          </h1>
          <ol className="list-decimal list-inside space-y-6 text-gray-300">
            <li className="mb-4">
              <span className="font-bold text-indigo-400">
                HERETIC.SCHOOL IS IN BETA.
              </span>{" "}
              Heretic.School is rapidly evolving, and we are working to improve
              the app experience as rapidly as possible. If you have any
              problems, please reach out to our founder Andrew directly at{" "}
              <a
                href="mailto:andrew@heretic.school"
                className="text-blue-400 hover:underline"
              >
                andrew@heretic.school
              </a>
              .
            </li>
            <li>
              Download the extension from the{" "}
              <a
                href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage"
                className="text-blue-400 hover:underline font-bold"
                onClick={handleDownloadClick}
              >
                Chrome Web Store
              </a>
              <span> </span>and please refresh your graph page after to
              initialize it. Happy learning!
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default ExtensionDownload;
