import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

const Header = () => {
  const navigate = useNavigate();
  const [session, setSession] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleExtensionDownload = () => {
    navigate("/extension-download");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gray-900 py-4 md:py-6">
      <nav className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img
              src="/heretic_school_logo.png"
              alt="Heretic.School"
              className="h-8 md:h-12 w-auto mr-2"
            />
            <span className="text-xl md:text-2xl font-bold text-white">
              Heretic.School
            </span>
          </Link>
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
          <div
            className={`md:flex items-center ${
              isMenuOpen ? "block" : "hidden"
            } mt-4 md:mt-0`}
          >
            <a
              href="https://x.com/garberchov/status/1834214083773468810"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mb-2 md:mb-0 md:mr-4 hover:text-indigo-400 px-4 py-2 rounded-lg text-sm font-bold transition duration-300 block md:inline-block w-full md:w-auto"
            >
              Changelog
            </a>
            <button
              onClick={handleExtensionDownload}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg text-sm font-bold hover:bg-blue-600 transition duration-300 w-full md:w-auto mb-2 md:mb-0 md:mr-4"
            >
              Download Extension (REQUIRED!)
            </button>
            {session ? (
              <button
                onClick={handleSignOut}
                className="bg-indigo-500 px-4 py-2 rounded hover:bg-indigo-600 transition duration-300 text-white w-full md:w-auto"
              >
                Sign Out
              </button>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-white mb-2 md:mb-0 md:mr-4 hover:text-indigo-400 bg-slate-600 px-4 py-2 rounded-lg text-sm font-bold hover:bg-slate-700 transition duration-300 block md:inline-block w-full md:w-auto"
                >
                  Sign In
                </Link>
                <Link
                  to="/login"
                  className="bg-indigo-500 px-4 py-2 rounded hover:bg-indigo-600 transition duration-300 text-white block md:inline-block w-full md:w-auto"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
